import { formatPrice } from '@white-label-helper/helper-payment';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { type CartItem } from '@white-label-types/parking-checkout';
import { type ManageBookingParsedOrderItem } from '@white-label-types/parking-booking';

const useDeposit = defineStore('deposit', () => {
  const totalDepositDetails = ref<null | {
    payNow: string,
    payOnArrival: string
  }>(null);

  const productsDepositDetails = ref<{
    name: string,
    depositAmount: string,
    arrivalAmount: string,
  }[]>([]);

  /**
   * Deposit details for the order
   *
   * @param details - Deposit details object.
   * @param details.pay_now - The amount to be paid upfront.
   * @param details.pay_on_arrival - The amount to be paid on arrival.
   */
  function storeDepositDetails({pay_now, pay_on_arrival, isEnabled = false}: {pay_now: number, pay_on_arrival: number, isEnabled: boolean}) {
    // We can use the "pay_on_arrival" to work out if it is a deposit-only product.
    // If the value is greater than 0, then it will be a deposit-only product
    if (isEnabled && pay_on_arrival > 0) {
      // We have a deposit-only item
      totalDepositDetails.value = {
        payNow: formatPrice(pay_now).toString(),
        payOnArrival: formatPrice(pay_on_arrival).toString()
      }
    } else {
      totalDepositDetails.value = null;
    }
  }

  /**
   * Deposit details for each item in the order
   *
   * @param products - An array of CartItem objects representing products.
   */
  function storeProductsDepositDetails(products: Array<CartItem |  ManageBookingParsedOrderItem>) {
    products.forEach((product) => {
      const { product_deposit: productDeposit } = product.totals;

      if (productDeposit > 0) {
        productsDepositDetails.value.push({
          name: product.inventory_item.name,
          depositAmount: formatPrice(productDeposit).toString(),
          arrivalAmount: formatPrice(product.totals.total - productDeposit).toString()
        });
      }
    })
  }

  return {
    totalDepositDetails,
    productsDepositDetails,
    storeDepositDetails,
    storeProductsDepositDetails,
  }
});

export default useDeposit;
