
// Packages
import Vue, { defineComponent } from 'vue';

// Static
import nationalities from './static-data/nationalities.json';
import countries from './static-data/countries.json';

// Components
import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import TextField from '../text-field/text-field.vue';
import TextFieldDate from '../text-field-date/text-field-date.vue';
import PhoneField from '../phone-field/phone-field.vue';
import FileUpload from '../file-upload/file-upload.vue';

import RadioButton from '../radio-button/radio-button.vue';
import IconAlert from '@white-label-icon/icon-alert';
import {
  add,
  DATE_TIME_FORMATS,
  guessTimezone,
  sub,
} from '@white-label-helper/date-utilities';
import BackButton from '../back-button/back-button.vue';
import { NAMED_ROUTES } from '@white-label-configuration/constants';
import {
  readBooking,
  readManageBookingItems,
} from '@white-label-store/manage-booking';
import { BookingGuestDetailsData } from '@white-label-types/account-booking';
import {
  getShareObjectUrl,
  postGuestDetails,
} from '@white-label-helper/api-manage-booking';

// Mixins
import auth from '@white-label-helper/mixin-auth';
import CheckboxGroup from '../checkbox-group/checkbox-group.vue';
import { merge } from 'lodash';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { email, maxLength, requiredIf } from '@white-label-helper/vuelidate';
import IconMessage from '../icon-message/icon-message.vue';

export default defineComponent({
  name: 'PostSalesQuestionnaire',
  components: {
    IconMessage,
    CheckboxGroup,
    RadioButton,
    BackButton,
    TextField,
    TextFieldDate,
    PhoneField,
    DropdownWrapper,
    IconAlert,
    FileUpload,
  },
  mixins: [auth],
  data() {
    return {
      guestPiiToken: '',
      loading: false,
      issuingCountry: { id: '', name: '' },
      nationality: { id: '', name: '' },
      leadAssistance: 'no',
      documentPreviews: {
        passport: '',
        visa: '',
      },
      dropdowns: {
        issuingCountryDropdownOpened: false,
        nationalityDropdownOpened: false,
        specialAssistanceDropdownOpened: false,
      },
      specialAssistance: {
        id: '1',
        name: this.$t('postSales.travelWithAGuide'),
      },
      specialAssistanceDropdown: [
        {
          id: 1,
          name: this.$t('postSales.travelWithAGuide') as string,
        },
      ],
      phoneValid: false,
      formData: {
        id: '',
        group: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        dob: '',
        email: '',
        phone: {
          phone_number: '',
          formatted_number: '',
          country_code: 'UK',
        },
        assistance: {
          is_required: false,
          type: '',
        },
        dietary_requirements: {
          is_required: false,
          comment: '',
          list: [],
        },
        uploads: {
          passport: {
            passport_reference: '',
            expiration_date: '',
            issuing_country: '',
            nationality: '',
            file_path: '',
          },
          visa: {
            visa_reference: '',
            file_path: '',
          },
        },
        origin: '',
        destination: '',
      } as BookingGuestDetailsData,
      isConnectingFlight: false,
      visaNeeded: false,
      visaOptions: [
        {
          label: this.$t('postSales.visaNeeded.dontRequired'),
          value: false,
        },
        {
          label: this.$t('postSales.visaNeeded.required'),
          value: true,
        },
      ],
      assistanceOptions: [
        {
          label: this.$t('postSales.assistanceNotRequired'),
          value: false,
        },
        {
          label: this.$t('postSales.assistanceRequired'),
          value: true,
        },
      ],
      dietaryRequirementsOptions: [
        {
          label: 'I don’t have any dietary requirements',
          value: false,
        },
        {
          label: 'I have dietary requirements',
          value: true,
        },
      ],
      dietaryOptions: [
        {
          label: 'Gluten intolerance',
          value: 'gluten_intolerance',
        },
        {
          label: 'Halal',
          value: 'halal',
        },
        {
          label: 'Kosher',
          value: 'kosher',
        },
        {
          label: 'Lactose intolerance',
          value: 'lactose_intolerance',
        },
        {
          label: 'Nut allergy',
          value: 'nut_allergy',
        },
        {
          label: 'Vegan',
          value: 'vegan',
        },
        {
          label: 'Vegetarian',
          value: 'vegetarian',
        },
      ],
      assistanceTypeOptions: [
        {
          id: 'WCHR',
          name: this.$t('postSales.assistanceOptions.WCHR'),
        },
        {
          id: 'WCHS',
          name: this.$t('postSales.assistanceOptions.WCHS'),
        },
        {
          id: 'WCHC',
          name: this.$t('postSales.assistanceOptions.WCHC'),
        },
        {
          id: 'DPNA',
          name: this.$t('postSales.assistanceOptions.DPNA'),
        },
        {
          id: 'BLND',
          name: this.$t('postSales.assistanceOptions.BLND'),
        },
        {
          id: 'DEAF',
          name: this.$t('postSales.assistanceOptions.DEAF'),
        },
      ],
      uploadsErrors: {
        passport: false,
        visa: false,
      },
    };
  },
  validations(): object {
    const passportFields = [
      this.formData.uploads.passport.nationality,
      this.formData.uploads.passport.issuing_country,
      this.formData.uploads.passport.passport_reference,
      this.formData.uploads.passport.expiration_date,
      this.formData.uploads.passport.file_path,
    ];
    const requiredIfAnyDefined = (fields) => {
      return requiredIf(function () {
        return fields.some((field) => field != null && field !== '');
      });
    };
    return {
      formData: {
        email: { email },
        phone: {
          phone_number: {
            isValid(): boolean {
              // @ts-ignore - `this` is not recognized in its context
              const isEmpty = !this.formData.phone?.phone_number?.length;
              // @ts-ignore - `this` is not recognized in its context
              return !isEmpty ? this.phoneValid : true;
            },
          },
        },
        uploads: {
          passport: {
            nationality: {
              required: requiredIfAnyDefined(passportFields),
            },
            issuing_country: {
              required: requiredIfAnyDefined(passportFields),
            },
            passport_reference: {
              required: requiredIfAnyDefined(passportFields),
            },
            expiration_date: {
              required: requiredIfAnyDefined(passportFields),
            },
            file_path: {
              required: requiredIfAnyDefined(passportFields),
              fileUploadError: () => !this.uploadsErrors.passport,
            },
          },
          visa: {
            visa_reference: {
              required: requiredIf(() => this.visaNeeded),
            },
            file_path: {
              required: requiredIf(() => this.visaNeeded),
              fileUploadError: () => !this.uploadsErrors.visa,
            },
          },
        },
        assistance: {
          type: {
            required: requiredIf(() => this.formData.assistance.is_required),
          },
        },
        dietary_requirements: {
          list: {
            required: requiredIf(
              () =>
                this.formData.dietary_requirements.is_required &&
                !this.formData.dietary_requirements.comment
            ),
          },
          comment: {
            required: requiredIf(
              () =>
                this.formData.dietary_requirements.is_required &&
                this.formData.dietary_requirements.list.length < 1
            ),
          },
        },
        origin: {
          required: requiredIf(
            () => this.isInboundProduct && this.isConnectingFlight
          ),
          maxLength: maxLength(30),
        },
        destination: {
          required: requiredIf(
            () => !this.isInboundProduct && this.isConnectingFlight
          ),
          maxLength: maxLength(30),
        },
      },
    };
  },
  computed: {
    DATE_TIME_FORMATS() {
      return DATE_TIME_FORMATS;
    },
    controlDate() {
      return {
        timeZone: guessTimezone(),
        minDate: add(new Date(), { days: 1 }),
        maxDate: add(new Date(), { years: 11 }),
      };
    },
    dobDates() {
      return {
        timeZone: guessTimezone(),
        minDate: sub(new Date(), { years: 100 }),
        maxDate: new Date(),
      };
    },
    bookingItem() {
      return (
        readManageBookingItems(this.$store).find(
          (booking) => booking.id === this.bookingId
        ) || {}
      );
    },
    hasPassedDeadline() {
      return this.bookingItem.hasPassedDeadline;
    },
    infoMessage() {
      return {
        type: 'info',
        text: this.$t('guestDetails.questionnaireRequirement'),
        iconClass: 'text-brand-100',
      };
    },
    piiData() {
      const booking = readBooking(this.$store);

      return booking.piiData.find(
        (questionnaire) => questionnaire.bookingItemId === this.bookingId
      );
    },
    bookingId(): number {
      return this.$cookies.get('guestPiiData').orderId;
    },
    guestData() {
      return (
        this.piiData?.guests.find((item) => item.id === this.guestPiiToken) ??
        {}
      );
    },
    countryList() {
      return countries.map((item: { name: string; code: string }) => ({
        id: item.code,
        name: item.name,
      }));
    },
    nationalityList() {
      return nationalities.map((item: string) => ({ id: item, name: item }));
    },
    getGuestIndex() {
      const guestIndex = this.piiData?.guests.findIndex(
        (item) => item.id === this.guestPiiToken
      );
      return guestIndex;
    },
    pageTitle() {
      if (this.getGuestIndex < 1) {
        return this.$t('postSales.leadTraveler');
      }

      return `Guest ${this.getGuestIndex + 1}`;
    },
    storageUrl() {
      return `${
        getAppVariable('regional_config').domains.booking
      }/${getAppVariable(
        'api_version'
      )}/account/pii-questions-data/signed-storage-url`;
    },
    dropdownValues() {
      return ['nationality', 'issuing_country'];
    },
    computedIssuingCountry: {
      get() {
        return (
          this.countryList.find(
            (item) => item.id === this.formData.uploads.passport.issuing_country
          ) || {
            id: '',
            name: '',
          }
        );
      },
      set(val): void {
        this.formData.uploads.passport.issuing_country = val.id;
      },
    },
    computedNationality: {
      get() {
        return (
          this.nationalityList.find(
            (item) => item.id === this.formData.uploads.passport.nationality
          ) || {
            id: '',
            name: '',
          }
        );
      },
      set(val): void {
        this.formData.uploads.passport.nationality = val.id;
      },
    },
    computedAssistanceType: {
      get() {
        return (
          this.assistanceTypeOptions.find(
            (item) => item.id === this.formData.assistance.type
          ) || {
            id: '',
            name: '',
          }
        );
      },
      set(val): void {
        this.formData.assistance.type = val.id;
      },
    },
    uploadRules() {
      return [
        (items: File[]) => {
          if (
            !items.every(({ name }) => {
              const regex = new RegExp(
                `\\.(${this.acceptedFileFormats
                  .map((format) => format.slice(1))
                  .join('|')})$`,
                'i'
              );
              return regex.test(name.toLowerCase());
            })
          ) {
            return this.$t('postSales.validations.fileUpload.fileFormat', {
              formats: this.acceptedFileFormats
                .map((format) => format.slice(1))
                .join(', ')
                .toUpperCase(),
            });
          }
          return '';
        },
        (items: File[]) => {
          const maxFileSize = 25 * 1024 * 1024; // 25 MB in bytes
          if (!items.every((item) => item.size <= maxFileSize)) {
            return this.$t('postSales.validations.fileUpload.fileSize');
          }
          return '';
        },
      ];
    },
    conditionallyNeededFields() {
      return {
        visa: this.visaNeeded,
        dietary_requirements: this.formData.dietary_requirements.is_required,
        assistance: this.formData.assistance.is_required,
        origin: this.isConnectingFlight && this.isInboundProduct,
        destination: this.isConnectingFlight && !this.isInboundProduct,
      };
    },
    acceptedFileFormats() {
      return ['.png', '.jpg', '.jpeg'];
    },
    isInboundProduct() {
      return this.bookingItem.inventory_item_details.direction === 'inbound';
    },
    travelSectionLocation: {
      get() {
        return this.isInboundProduct
          ? this.formData.origin
          : this.formData.destination;
      },
      set(value: string) {
        if (this.isInboundProduct) {
          this.formData.origin = value;
        } else {
          this.formData.destination = value;
        }
      },
    },
    travelSection() {
      const dataKey = this.isInboundProduct ? 'origin' : 'destination';
      return {
        header: this.$t(`postSales.${dataKey}.title`),
        options: [
          {
            label: this.$t(`postSales.${dataKey}.options.directFlight`),
            value: false,
          },
          {
            label: this.$t(`postSales.${dataKey}.options.connectedFlight`),
            value: true,
          },
        ],
        fieldLabel: this.$t(`postSales.${dataKey}.fieldLabel`),
        fieldModel: this.formData[dataKey],
        errorObj: this.$v.formData[dataKey],
        customErrorMessages: {
          required: this.$t(`postSales.validations.${dataKey}.required`),
          maxLength: this.$t(`postSales.validations.${dataKey}.maxLength`),
        },
      };
    },
  },
  watch: {
    visaNeeded() {
      if (this.visaNeeded) {
        this.$v.formData.uploads.visa.visa_reference.$reset();
        this.$v.formData.uploads.visa.file_path.$reset();
      }
    },
  },
  async mounted() {
    this.guestPiiToken = this.$cookies.get('guestPiiData')?.piiToken;
    this.populateState();
    await this.getDocumentPreviews();
  },
  methods: {
    async getDocumentPreviews() {
      if (!this.guestData.uploads) return;
      if (this.guestData.uploads?.passport?.file_path) {
        const { url } = await getShareObjectUrl(this.getToken, {
          key: this.guestData.uploads.passport.file_path,
        });
        this.documentPreviews.passport = url;
      }
      if (this.guestData.uploads?.visa?.file_path) {
        const { url } = await getShareObjectUrl(this.getToken, {
          key: this.guestData.uploads.visa.file_path,
        });
        this.documentPreviews.visa = url;
      }
    },
    async goToGuestDetails() {
      await this.$router.push(NAMED_ROUTES.booking_guest_details.home);
    },
    populateState() {
      Vue.set(this, 'formData', merge(this.formData, this.guestData));

      if (
        this.guestData.uploads?.visa &&
        Object.keys(this.guestData.uploads.visa).length > 0
      ) {
        this.visaNeeded = true;
      }

      if (this.guestData.destination || this.guestData.origin) {
        this.isConnectingFlight = true;
      }
    },
    async submitForm() {
      this.$v.formData.$touch();
      if (this.$v.$invalid) return;
      try {
        await postGuestDetails(
          this.getToken,
          this.$cookies.get('guestPiiData').orderId,
          this.prepareFormData(this.formData),
          this.isConnectingFlight
        );

        await this.$router.push(NAMED_ROUTES.booking_guest_details.home);
      } catch (e) {
        console.log(e);
      }
    },
    prepareFormData(obj: any): any {
      if (Array.isArray(obj)) {
        return obj
          .filter((item) => item != null && item !== '')
          .map(this.prepareFormData);
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj)
          .filter(([key, value]) => {
            if (
              key === 'phone' &&
              typeof value === 'object' &&
              value !== null
            ) {
              // Exclude the phone object if country_code is empty
              return value.phone_number !== '';
            }
            if (Object.keys(this.conditionallyNeededFields).includes(key)) {
              return this.conditionallyNeededFields[key];
            }
            return (
              value != null &&
              value !== '' &&
              !(Array.isArray(value) && value.length === 0)
            );
          })
          .reduce((acc, [key, value]) => {
            if (
              this.dropdownValues.includes(key) &&
              typeof value === 'object' &&
              'id' in value
            ) {
              acc[key] = value.id;
            } else {
              acc[key] = this.prepareFormData(value);
            }
            return acc;
          }, {} as any);
      }

      return obj;
    },
    fileUpload(field: 'passport' | 'visa', event: File & { key: string }) {
      this.$v.formData.uploads[field].file_path.$reset();
      if (event.key) {
        this.formData.uploads[field].file_path = event.key;
        this.uploadsErrors[field] = false;
      }
    },
    setUploadsError(field: 'passport' | 'visa') {
      this.uploadsErrors[field] = true;
    },
  },
});
