
// Packages
import { defineComponent, PropType } from 'vue';
import { mapState } from 'pinia';

// Constants
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';

// Helpers
import {
  guessTimezone,
  parseISO,
  utcToZonedTime,
  isBefore,
  isAfter,
  isSameDay,
} from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Types
import type { Booking } from '@white-label-types/account-booking';

// Store
import { useBookingsStore } from '@white-label-store/bookings';

// Components
import BookingsNoResults from '../bookings-no-results/bookings-no-results.vue';
import BookingItem from '../booking-item/booking-item.vue';
import TextButton from '../text-button/text-button.vue';

export default defineComponent({
  name: 'BookingsList',

  components: {
    BookingItem,
    BookingsNoResults,
    DotsLoader,
    TextButton,
  },

  props: {
    bookings: {
      type: Array as PropType<Booking[]>,
      required: true,
    },
    loadingNextPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayAllPastBookings: false,
      currentDate: utcToZonedTime(
        new Date(),
        getAppVariable('poi.timezone') || guessTimezone()
      ),
    };
  },

  computed: {
    ...mapState(useBookingsStore, ['pagination', 'bookingsNextPageCount']),
    partnerTimeZone(): string {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    currentBookings(): Booking[] {
      return this.sortArrayWithBookingDate(
        this.bookings.filter((booking) => {
          if (!booking?.orderItems) return [];

          const currentlyActiveItemsInBooking = booking.orderItems.filter(
            (item) => {
              const orderDate =
                item.closedAt?.datetime || item.startedAt?.datetime;

              return isSameDay(this.currentDate, parseISO(orderDate));
            }
          );

          return (
            currentlyActiveItemsInBooking.length &&
            booking.status.toUpperCase() !== BOOKING_STATUSES.CANCELLED
          );
        })
      );
    },

    currentBookingsCount(): number {
      return this.currentBookings.length;
    },

    currentBookingsExists(): boolean {
      return !!this.currentBookings.length;
    },

    upcomingBookings(): Booking[] {
      return this.sortArrayWithBookingDate(
        this.bookings.filter((booking: Booking) => {
          if (!booking?.orderItems) return [];

          const allBookingsContainingAnItemInTheFuture =
            booking.orderItems.filter((item) => {
              const orderDate =
                item.closedAt?.datetime || item.startedAt?.datetime;

              return (
                isAfter(parseISO(orderDate), this.currentDate) &&
                !isSameDay(this.currentDate, parseISO(orderDate))
              );
            });

            const currentlyActiveItemsInBooking = booking.orderItems.filter(
            (item) => {
              const orderDate =
                item.closedAt?.datetime || item.startedAt?.datetime;

              return isSameDay(this.currentDate, parseISO(orderDate));
            }
          );

          return (
            allBookingsContainingAnItemInTheFuture.length &&
            !currentlyActiveItemsInBooking.length &&
            booking.status.toUpperCase() !== BOOKING_STATUSES.CANCELLED
          );
        })
      );
    },

    upcomingBookingsCount(): number {
      return this.upcomingBookings.length;
    },

    upcomingBookingsExists(): boolean {
      return !!this.upcomingBookings.length;
    },

    pastBookings(): Booking[] {
      return this.sortArrayWithBookingDate(
        this.bookings.filter((booking) => {
          if (!booking?.orderItems) return [];

          const allOrderItemsAreInThePast = booking.orderItems.filter(
            (item) => {
              const orderDate =
                item.closedAt?.datetime || item.startedAt?.datetime;
              return (
                isBefore(parseISO(orderDate), this.currentDate) &&
                !isSameDay(this.currentDate, parseISO(orderDate))
              );
            }
          );

          return (
            allOrderItemsAreInThePast.length === booking.orderItems.length ||
            booking.status.toUpperCase() === BOOKING_STATUSES.CANCELLED
          );
        })
      );
    },

    pastBookingsCount(): number {
      return this.pastBookings.length;
    },

    pastBookingsExists(): boolean {
      return !!this.pastBookings.length;
    },

    displayViewMorePastBookingsButton(): boolean {
      return !this.loadingNextPage && this.pagination.hasNextPage;
    },

    remainingPastBookingsToggleBtnText(): string {
      return this.$tc(
        'bookings.viewMorePastBookings',
        this.bookingsNextPageCount
      );
    },
  },

  mounted() {
    // making bookings array reactive
    setInterval(() => {
      this.currentDate = utcToZonedTime(new Date(), this.partnerTimeZone);
    }, 60000);
  },

  methods: {
    sortArrayWithBookingDate(list: Booking[]): Booking[] {
      const getOrderDate = (orderItem) => {
        if (!orderItem) return;
        return orderItem.direction === 'inbound'
          ? orderItem.startedAt.dateTime
          : orderItem.closedAt.datetime;
      };

      function getEarliestDate(orderItems) {
        return orderItems.reduce((earliest, item) => {
          const itemDate = new Date(getOrderDate(item));
          return itemDate < earliest ? itemDate : earliest;
        }, new Date(orderItems[0].startedAt.datetime));
      }

      // Sort the list by ascending started_at for current/upcoming bookings
      // and descending started_at for past bookings
      const sortedList = [...list].sort((a, b) => {
        const earliestA = getEarliestDate(a.orderItems);
        const earliestB = getEarliestDate(b.orderItems);
        if (a.status === 'past_booking' || a.status === 'cancelled') {
          return earliestB - earliestA;
        }
        return earliestA - earliestB;
      });
      return sortedList.map(item => ({
        ...item,
        orderItems: [...item.orderItems].sort((a, b) => {
        const earliestA = new Date(getOrderDate(a));
        const earliestB = new Date(getOrderDate(b));
        return earliestA - earliestB;
      })
      }));
    },
    emitLoadNextPage(): void {
      this.$emit('load-next-page');
    },
  },
});
