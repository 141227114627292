
import { defineComponent } from 'vue';
import {
  format,
  parseISO,
  DATE_TIME_FORMATS,
  addMinutes,
  formatWithLocale,
} from '@white-label-helper/date-utilities';

export default defineComponent({
  name: 'EntryExitInfo',

  components: {
    IconCalendar: () => import('@white-label-icon/icon-calendar'),
    IconWatch: () => import('@white-label-icon/icon-watch'),
  },

  props: {
    label: {
      type: String,
      default: 'Entry',
    },

    dateTimeString: {
      type: String,
      default: '',
    },

    customClasses: {
      type: Object,
      default: () => ({
        inline: false,
        halfWidth: false,
        bigTitle: false,
        labelPaddingRight: false,
      }),
      required: false
    },

    period: {
      type: Number,
      default: 0,
    },

    endPeriodTime: {
      type: String,
      default: '',
    },
    visitingDuration: {
      type: String,
      default: '',
    },
  },

  computed: {
    date(): string {
      return this.dateTimeString
        ? formatWithLocale(
            parseISO(this.dateTimeString),
            DATE_TIME_FORMATS.month_day_year,
            this.$i18n.locale
          )
        : '';
    },

    time(): string {
      return this.dateTimeString
        ? format(parseISO(this.dateTimeString), DATE_TIME_FORMATS.time)
        : '';
    },

    endTime(): string {
      return this.endPeriodTime
        ? format(parseISO(this.endPeriodTime), DATE_TIME_FORMATS.time)
        : this.period && this.dateTimeString
        ? format(
            addMinutes(parseISO(this.dateTimeString), this.period),
            DATE_TIME_FORMATS.time
          )
        : '';
    },
    enableAetherImprovements() {
      return this.$launchDarkly.variation(
        'PREM-636-Aether-Phase-1-Improvements'
      );
    },
  },
});
