
// Packages
import { defineComponent, PropType } from 'vue';
import { Portal } from 'portal-vue';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';
import {
  parseExtras,
  parseTaxesAndFees,
} from '@white-label-helper/data-parsers-summary';

// Mixins
import multiBasket from '@white-label-helper/mixin-multi-basket';

// Constants
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

// Stores
import {
  readDiscount,
  readIsCancelled,
  readIsPastBooking,
  readIsConfirmed,
  readIsInProgress,
  readProductTypesCategories,
  readManageBookingItems,
} from '@white-label-store/manage-booking';
import useDeposit from '@white-label-store/deposits';

// Types
import type { ManageBookingState as State } from '@white-label-types/stores';
import type {
  SummaryExtras,
  SummaryDiscount,
} from '@white-label-types/summary';
import type { SummaryProps } from '@white-label-types/component';

// Components
import ManageBookingOrderDetails from '../manage-booking-order-details/manage-booking-order-details.vue';
import ManageBookingButtonBlock from '../manage-booking-button-block/manage-booking-button-block.vue';
import SummaryLounges from '../summary-lounges/summary-lounges.vue';
import SummaryParking from '../summary-parking/summary-parking.vue';
import PaymentSummary from '../payment-summary/payment-summary.vue';
import StickySidebar from '../sticky-sidebar/sticky-sidebar.vue';
import GuestHint from '../guest-hint/guest-hint.vue';

export default defineComponent({
  name: 'ManageBookingMainSection',
  components: {
    GuestHint,
    MBOrderDetails: ManageBookingOrderDetails,
    MBButtonBlock: ManageBookingButtonBlock,
    StickySidebar,
    SummaryLounges,
    SummaryParking,
    PaymentSummary,
    Portal,
  },
  mixins: [multiBasket],
  props: {
    orderData: {
      type: Object as PropType<State['manageBookingState']>,
      required: true,
    },
    modifications: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    discount(): SummaryDiscount | null {
      return readDiscount(this.$store);
    },

    isCancelled(): boolean {
      return readIsCancelled(this.$store);
    },

    isPastBooking(): boolean {
      return readIsPastBooking(this.$store);
    },

    isConfirmed(): boolean {
      return readIsConfirmed(this.$store);
    },

    isOrderItemsCancelledOrPastBooking(): boolean {
      const items = readManageBookingItems(this.$store);
      return items.every(
        (item) =>
          item.status === BOOKING_STATUSES.CANCELLED ||
          item.status === BOOKING_STATUSES.PAST_BOOKING
      );
    },

    isInProgress(): boolean {
      return readIsInProgress(this.$store);
    },

    /**
     * The price of the item when being refunded
     * @returns The price formated or an empty string
     */
    orderTotalFormatted(): string | Intl.NumberFormat {
      if (this['hasDeposit']) {
        return this['hasDeposit'].payNow;
      }

      return typeof this.orderData.orderTotals?.total === 'number'
        ? formatPrice(this.orderData.orderTotals.total)
        : '';
    },

    itemSubtotalFormatted(): string | Intl.NumberFormat {
      return typeof this.orderData.totals?.subtotal === 'number'
        ? formatPrice(this.orderData.totals.subtotal)
        : '';
    },

    itemTotalFormatted(): string | Intl.NumberFormat {
      return typeof this.orderData.totals?.total === 'number'
        ? formatPrice(this.orderData.totals.total)
        : '';
    },

    taxes(): ReturnType<typeof parseTaxesAndFees> {
      return parseTaxesAndFees(this.orderData.taxes);
    },

    fees(): ReturnType<typeof parseTaxesAndFees> {
      return parseTaxesAndFees(this.orderData.fees);
    },

    extras(): SummaryExtras | {} {
      return this.orderData.cancellationProtection && !this.isOrderCancelled
        ? parseExtras(this.orderData.cancellationProtection)
        : {};
    },

    isOrderCancelled(): boolean {
      return this.orderData.status === BOOKING_STATUSES.CANCELLED;
    },

    isOrderPartiallyCancelled(): boolean {
      return !this.isOrderCancelled && this.orderData.items.some(
          (item) => item.status === 'cancelled'
        );
    },

    cancellationProtectionTotalFormatted(): string | Intl.NumberFormat {
      return this.isOrderCancelled &&
        this.orderData?.cancellationProtection?.totals?.total
        ? formatPrice(this.orderData?.cancellationProtection?.totals?.total)
        : '';
    },

    amendmentFeeFormatted(): string | Intl.NumberFormat {
      const { amendmentFee } = this.orderData;
      return typeof amendmentFee === 'number' && amendmentFee > 0
        ? formatPrice(amendmentFee)
        : '';
    },

    priceDifference(): string {
      let totalRefunded = 0;
      if (this.isMultiBasket || this.isCrossSell)
        totalRefunded =
          this.orderData.orderTotals?.cancellation_refunded_total || 0;
      else if ('totalRefunded' in this.orderData)
        totalRefunded = this.orderData.totalRefunded || 0;
      return String(totalRefunded);
    },

    priceDifferenceFormatted(): string | Intl.NumberFormat {
      return this.isOrderCancelled ? formatPrice(this.priceDifference) : '';
    },

    cancellationFeeFormatted(): string | Intl.NumberFormat {
      let cancellationFee = 0;
      if (this.isMultiBasket || this.isCrossSell) {
        cancellationFee = this.orderData.orderTotals?.cancellation_fee_total_formatted?.without_currency || 0;
      } else {
        cancellationFee = this.orderData.cancellationInfo?.cancellation_fee || 0;
      }
      return this.isOrderCancelled ? formatPrice(cancellationFee) : '';
    },

    valueAfterRefundWithoutCancellation(): string {
      if (this.isOrderCancelled) return '';
      const total = this.orderData.orderTotals?.total ?? 0;
      const { totalRefunded } = this.orderData;
      if (totalRefunded && totalRefunded > 0) {
        return `${formatPrice(total - totalRefunded)}`;
      }
      return '';
    },

    /**
     * Checks if there is a deposit but checking for the existence of a null
     * value or not
     *
     * @returns Returns true if there is a deposit, otherwise false.
     */
    hasDeposit(): false | { payNow: string; payOnArrival: string } {
      const depositOnly = useDeposit();
      if (depositOnly.totalDepositDetails === null) {
        return false;
      }

      return depositOnly.totalDepositDetails;
    },

    summaryProps(): SummaryProps {
      const cancellationFee =
        this.orderData.cancellationInfo?.cancellation_fee || 0;
      const totalRefunded = this.orderData.totalRefunded || 0;
      const amendmentFee =
        typeof this.amendmentFee === 'number' && this.amendmentFee > 0
          ? formatPrice(this.amendmentFee)
          : null;

      return {
        hasPaid: true,
        orderName: this.orderData.parkingName || '',
        orderTotal: this.orderTotalFormatted,
        itemSubtotal: this.itemSubtotalFormatted,
        itemTotal: this.itemTotalFormatted,
        taxes: this.taxes,
        fees: this.fees,
        extras: this.extras,
        cancellationProtection: this.cancellationProtectionTotalFormatted,
        isRefund: this.isOrderCancelled,
        priceDifference: formatPrice(totalRefunded),
        cancellationFee: this.isOrderCancelled
          ? formatPrice(cancellationFee)
          : '',
        discount: this.discount,
        amendmentFee,
        valueAfterRefundWithoutCancellation:
          this.valueAfterRefundWithoutCancellation,
        itemTotalOld: this.orderData?.orderTotals?.total_without_discount
          ? formatPrice(this.orderData.orderTotals.total_without_discount)
          : '',
        bookingFee: this.orderData?.bookingFee?.is_enabled
          ? formatPrice(this.orderData.bookingFee.amount)
          : '',
      };
    },

    /**
     * Returns value of featuree flag: ECOM-1770-Travel-Extras-Payment-Summary
     * @returns a boolean.
     */
     displayTravelExtrasSummary(){
      return this['$launchDarkly'].variation('ECOM-1770-Travel-Extras-Payment-Summary');
    },

    /**
     * Returns value of featuree flag: ECOM_1771_MOVE_CANCELLATION_PROTECTION
     * @returns a boolean.
     */
    // TODO: Remove when ECOM_1771_MOVE_CANCELLATION_PROTECTION is live
    isCrossSell() {
      return this['$launchDarkly'].variation('ECOM_1771_MOVE_CANCELLATION_PROTECTION');
    },

    addGuestDetailsEnabled(): boolean {
      return this.$launchDarkly.variation(
        'PREM-797-Aether-Payment-Summary-Add-Guest-Details'
      );
    },
  },

  methods: {
    isProductTypeIncluded(key: string): boolean {
      return readProductTypesCategories(this.$store).includes(key);
    },
  },
});
